import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "no-direct-payment" };
const _hoisted_2 = { class: "no-direct-payment__content" };
const _hoisted_3 = { class: "z-h5 no-direct-payment__content__title" };
const _hoisted_4 = { class: "no-direct-payment__content__wrapper" };
const _hoisted_5 = { class: "z-b4 f-inter no-direct-payment__content__subtitle" };
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { class: "z-b5 f-inter no-direct-payment__content__free-pass" };
const _hoisted_8 = { class: "z-b4 f-inter no-direct-payment__content__description" };
const _hoisted_9 = { class: "no-direct-payment__content__info" };
const _hoisted_10 = {
    key: 0,
    class: "z-b4 f-inter"
};
const _hoisted_11 = {
    key: 1,
    class: "z-b4 f-inter"
};
const _hoisted_12 = { class: "z-b4 f-inter" };
const _hoisted_13 = { class: "no-direct-payment__contact" };
const _hoisted_14 = {
    key: 0,
    class: "no-direct-payment__book-url"
};
const _hoisted_15 = {
    key: 0,
    class: "no-direct-payments__add-to-cal"
};
import { computed, ref } from "vue";
import Modal from "@components/Core/Modal.vue";
import { validator } from "@ts/Util/i18n";
import ContactButtonHandler from "@components/ContactButton/ContactButtonHandler.vue";
import ZButton from '@components/Core/ZButton.vue';
import Loading from '@components/Util/Loading.vue';
import AddToSchedule from "@components/ClassDetails/AddToSchedule.vue";
import { LEAD_SOURCES } from "@ts/Util/Leads";
import ContactInstructorHandler from '@modules/InstructorPage/ContactInstructorHandler.vue';
import { mixpanelServiceTrack } from '@ts/Util/analytics';
const localT = {
    title: 'Count me in!',
    subtitle: 'Requires a membership',
    description: 'A membership is required to access this class. Contact the location for more info. Free passes are available at location.',
    contactLabel: 'Contact',
    copiedToClipboard: 'Copied!',
    freePassText: 'Free pass available',
    website: 'Website',
    locationWebsite: 'Location website',
    addToSchedule: 'Add to schedule',
    addedToSchedule: 'Added to schedule',
    emailSubmitted: 'Email sent to your instructor'
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'NoDirectPayment',
    props: {
        t: {
            type: Object,
            default: () => localT,
            validator: validator(localT)
        },
        show: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        hasFreePass: {
            type: Boolean,
            required: false,
            default: false
        },
        phone: {
            type: String,
            required: false,
            default: '',
        },
        email: {
            type: String,
            required: false,
            default: ''
        },
        locationDescription: {
            type: String,
            required: false,
            default: ''
        },
        locationStreet: {
            type: String,
            required: false,
            default: ''
        },
        locationLocality: {
            type: String,
            required: false,
            default: ''
        },
        locationPostalCode: {
            type: String,
            required: false,
            default: ''
        },
        formattedAddress: {
            type: String,
            required: false,
            default: ''
        },
        bookingUrl: {
            type: String,
            required: false,
            default: ''
        },
        goBackUrl: {
            type: String,
            required: false,
            default: ''
        },
        redirectUrl: {
            type: String,
            required: false,
            default: ''
        },
        classId: {
            type: String,
            required: false,
            default: ''
        },
        whatsapp: {
            type: String,
            required: false,
            default: ''
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        startDate: {
            type: String,
            required: false,
            default: ''
        },
        endDate: {
            type: String,
            required: false,
            default: ''
        },
        showAddToScheduleDropdown: {
            type: Boolean,
            required: false,
            default: false
        },
        instructor: {
            type: Object,
            required: true
        },
        instructorContactActionBase: {
            type: String,
            required: false,
            default: ''
        },
        recaptchaKey: {
            type: String,
            default: ''
        },
        requesterName: {
            type: String,
            default: ''
        },
        requesterEmail: {
            type: String,
            default: ''
        },
    },
    emits: ['closed', 'create-lead', 'schedule-class', 'on-send-message', 'toast:message'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const showContactModal = ref(false);
        const descriptionText = computed(() => props.description || props.t.description);
        const redirectToExitPage = () => {
            mixpanelServiceTrack('TrackClickedNotRegisterableClass', {
                locationWebsite: props.bookingUrl,
            });
            emit('closed');
            window.open(props.redirectUrl, '_blank');
        };
        const nameAndStreetText = computed(() => `${props.locationDescription} ${props.locationStreet}`.trim());
        const handleAddToSchedule = () => {
            emit('schedule-class');
            emit('create-lead', LEAD_SOURCES.ADD_TO_SCHEDULE);
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(Modal, {
                    show: __props.show && !showContactModal.value,
                    "mobile-drawer": "",
                    "close-when-click-on-background": "",
                    "show-close-button": true,
                    "custom-styles": { modal: { 'max-width': '76.875rem', 'width': '40%' } },
                    onClosed: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('closed')))
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                            (props.loading)
                                ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createElementVNode("div", _hoisted_2, [
                                        _createElementVNode("h5", _hoisted_3, [
                                            _createElementVNode("b", null, _toDisplayString(__props.t.title), 1)
                                        ]),
                                        _createElementVNode("div", _hoisted_4, [
                                            _createElementVNode("div", null, [
                                                _createElementVNode("p", _hoisted_5, _toDisplayString(__props.t.subtitle), 1)
                                            ]),
                                            (__props.hasFreePass)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                                    _createElementVNode("span", _hoisted_7, _toDisplayString(__props.t.freePassText), 1)
                                                ]))
                                                : _createCommentVNode("", true)
                                        ]),
                                        _createElementVNode("p", _hoisted_8, _toDisplayString(descriptionText.value), 1),
                                        _createElementVNode("div", _hoisted_9, [
                                            (nameAndStreetText.value)
                                                ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(nameAndStreetText.value), 1))
                                                : _createCommentVNode("", true),
                                            (__props.locationLocality && __props.locationPostalCode)
                                                ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(`${__props.locationLocality}, ${__props.locationPostalCode}`), 1))
                                                : _createCommentVNode("", true),
                                            _createElementVNode("p", _hoisted_12, _toDisplayString(__props.phone), 1)
                                        ])
                                    ]),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(["no-direct-payment__buttons", { 'two-col': __props.bookingUrl }])
                                    }, [
                                        _createElementVNode("div", _hoisted_13, [
                                            _createVNode(ContactButtonHandler, {
                                                "always-show-label": "",
                                                t: __props.t,
                                                "has-phone": __props.instructor.hasPhone,
                                                "has-email": __props.instructor.hasEmail,
                                                "has-whatsapp": __props.instructor.hasWhatsapp,
                                                "instructor-pid": __props.instructor.pid,
                                                instructor: __props.instructor,
                                                "instructor-contact-action": `${__props.instructorContactActionBase}/${__props.instructor.pid}`,
                                                "handle-email-externally": "",
                                                variety: "phoenix",
                                                "onOption:email": _cache[0] || (_cache[0] = ($event) => (showContactModal.value = true))
                                            }, null, 8, ["t", "has-phone", "has-email", "has-whatsapp", "instructor-pid", "instructor", "instructor-contact-action"])
                                        ]),
                                        (__props.bookingUrl)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                                _createVNode(ZButton, {
                                                    variety: "optimus",
                                                    compact: "desktop-only",
                                                    onClick: redirectToExitPage
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(__props.t.locationWebsite), 1)
                                                    ]),
                                                    _: 1
                                                })
                                            ]))
                                            : _createCommentVNode("", true)
                                    ], 2),
                                    (__props.startDate && __props.endDate && __props.title && descriptionText.value)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                            _createVNode(AddToSchedule, {
                                                t: __props.t,
                                                "show-dropdown": __props.showAddToScheduleDropdown,
                                                "event-name": __props.title,
                                                "event-description": descriptionText.value,
                                                "start-date": new Date(__props.startDate),
                                                "end-date": new Date(__props.endDate),
                                                location: __props.formattedAddress,
                                                onAddToSchedule: handleAddToSchedule
                                            }, null, 8, ["t", "show-dropdown", "event-name", "event-description", "start-date", "end-date", "location"])
                                        ]))
                                        : _createCommentVNode("", true)
                                ], 64))
                        ])
                    ]),
                    _: 1
                }, 8, ["show"]),
                _createVNode(ContactInstructorHandler, {
                    t: __props.t,
                    "show-modal": showContactModal.value && __props.show,
                    instructor: __props.instructor,
                    "requester-name": __props.requesterName,
                    "requester-email": __props.requesterEmail,
                    "recaptcha-key": __props.recaptchaKey,
                    onClosed: _cache[2] || (_cache[2] = ($event) => (showContactModal.value = false)),
                    onOnSendMessage: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('on-send-message', $event))),
                    "onToast:message": _cache[4] || (_cache[4] = ($event) => (_ctx.$emit('toast:message', $event)))
                }, null, 8, ["t", "show-modal", "instructor", "requester-name", "requester-email", "recaptcha-key"])
            ], 64));
        };
    }
});
