import { post } from "@ts/Util/http";
import { csrfToken } from "@ts/Util/security";
import { currentUserLocale } from "@ts/Util/i18n";
const locale = currentUserLocale() ?? 'en-US';
export const LEAD_SOURCES = {
    ADD_TO_SCHEDULE: 'Add to Schedule',
};
export const createLead = async (classId, source = 'Zumba.com', followInstructor = true) => {
    const sourceWithoutParams = source.split("?")[0];
    return await post(`/${locale}/buy/createLead/${classId}`, csrfToken(), {
        source: sourceWithoutParams,
        followInstructor
    });
};
