import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "added-to-schedule" };
const _hoisted_2 = { class: "added-to-schedule__link f-inter" };
const _hoisted_3 = { class: "add-to-schedule__link f-inter" };
import { validator } from '@ts/Util/i18n';
import CalendarsDropDown from "@components/Calendar/CalendarsDropdown.vue";
import ScheduleSvg from '@bx-icons/regular/bx-calendar-alt.svg';
import CheckSvg from '@bx-icons/regular/bx-check.svg';
import ArrowDownSvg from '@bx-icons/regular/bx-chevron-down.svg';
const localT = {
    addToSchedule: 'Add to schedule',
    addedToSchedule: 'Added to schedule',
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'AddToSchedule',
    props: {
        t: {
            type: Object,
            default: () => localT,
            validator: validator(localT)
        },
        showDropdown: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: '',
            required: false
        },
        eventDescription: {
            type: String,
            required: true,
        },
        startDate: {
            type: Date,
            required: true,
        },
        endDate: {
            type: Date,
            required: true,
        },
        location: {
            type: String,
            default: '',
            required: false
        },
        showCalendar: {
            type: Boolean,
            default: false
        },
    },
    emits: [
        'add-to-schedule',
    ],
    setup(__props, { emit: __emit }) {
        const emits = __emit;
        const handleAddToSchedule = () => {
            emits('add-to-schedule');
        };
        return (_ctx, _cache) => {
            return (__props.showDropdown)
                ? (_openBlock(), _createBlock(CalendarsDropDown, {
                    key: 0,
                    class: "added-to-schedule-dropdown",
                    "event-name": __props.title,
                    "event-description": __props.eventDescription,
                    "start-date": __props.startDate,
                    "end-date": __props.endDate,
                    location: __props.location,
                    "start-open": __props.showCalendar
                }, {
                    toggle: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                            _createVNode(_unref(CheckSvg), { class: "added-to-schedule__check-icon" }),
                            _createElementVNode("span", _hoisted_2, _toDisplayString(__props.t.addedToSchedule), 1),
                            _createVNode(_unref(ArrowDownSvg), { class: "added-to-schedule__arrow-down-icon" })
                        ])
                    ]),
                    _: 1
                }, 8, ["event-name", "event-description", "start-date", "end-date", "location", "start-open"]))
                : (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    class: "add-to-schedule f-inter",
                    href: "#",
                    onClick: _withModifiers(handleAddToSchedule, ["prevent"])
                }, [
                    _createVNode(_unref(ScheduleSvg), { class: "add-to-schedule__icon" }),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(__props.t.addToSchedule), 1)
                ]));
        };
    }
});
