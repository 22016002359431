export var AvailableContactTypes;
(function (AvailableContactTypes) {
    AvailableContactTypes["WHATSAPP"] = "Whatsapp";
    AvailableContactTypes["EMAIL"] = "Email";
    AvailableContactTypes["SMS"] = "SMS";
})(AvailableContactTypes || (AvailableContactTypes = {}));
const translationDefaults = {
    contactLabel: 'Contact',
    copiedToClipboard: 'Copied !'
};
import { ref, defineComponent, computed } from 'vue';
import { validateTranslations } from "@ts/Util/i18n";
import { isMobileReactive } from '@ts/Util/responsiveness';
import EnvelopeSvg from '@icons/envelope.svg';
import WhatsappSvg from '@icons/whatsapp.svg';
import CommentSvg from '@icons/comment.svg';
import ZButton from '@components/Core/ZButton.vue';
import { post } from "@ts/Util/http";
import { csrfToken } from "@ts/Util/security";
import ContactInstructorHandler from '@modules/InstructorPage/ContactInstructorHandler.vue';
export default defineComponent({
    name: 'ContactButton',
    components: {
        EnvelopeSvg,
        WhatsappSvg,
        CommentSvg,
        ZButton,
        ContactInstructorHandler
    },
    props: {
        hasPhone: {
            type: Boolean,
            default: false,
        },
        hasEmail: {
            type: Boolean,
            default: false,
        },
        hasWhatsapp: {
            type: Boolean,
            default: false,
        },
        instructorPid: {
            type: String,
            required: true,
        },
        wide: {
            type: String,
            default: 'always',
            validator: (value) => {
                return [
                    'never',
                    'always',
                    'mobile-only',
                    'desktop-only',
                ].includes(value);
            },
        },
        compact: {
            type: String,
            default: 'desktop-only',
            validator: (value) => {
                return [
                    'never',
                    'always',
                    'mobile-only',
                    'desktop-only',
                ].includes(value);
            },
        },
        variety: {
            type: String,
            default: 'cyclops',
            validator: (value) => {
                // You can add more variety types here
                return [
                    'optimus',
                    'robin',
                    'phoenix',
                    'cyclops',
                    'rogue',
                    'love',
                    'plum',
                ].includes(value);
            },
        },
        alwaysShowLabel: {
            type: Boolean,
            default: false,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        instructorContactAction: {
            type: String,
            required: false,
            default: ''
        },
        showModal: {
            type: Boolean,
            default: false
        },
        instructorDisplayName: {
            type: String,
            required: false,
            default: ''
        },
        instructorProfilePhotoUrl: {
            type: String,
            required: false,
            default: ''
        },
        instructorLocation: {
            type: String,
            required: false,
            default: ''
        },
        requesterName: {
            type: String,
            default: ''
        },
        requesterEmail: {
            type: String,
            default: ''
        },
        recaptchaKey: {
            type: String,
            default: ''
        },
        handleEmailExternally: {
            type: Boolean,
            default: false
        }
    },
    emits: ['option:email', 'option:whatsapp', 'option:sms', 'toast:message', 'on-send-message', 'recaptcha:failure', 'clicked:contact'],
    setup(props, { emit }) {
        const showTooltip = ref(false);
        const copiedToClipboardTooltip = ref(false);
        const isMobile = isMobileReactive();
        const contactTypes = computed(() => {
            const available = [];
            if (!props.instructorContactAction) {
                return available;
            }
            if (props.hasWhatsapp)
                available.push(AvailableContactTypes.WHATSAPP);
            if (props.hasEmail)
                available.push(AvailableContactTypes.EMAIL);
            if (props.hasPhone && isMobile.value)
                available.push(AvailableContactTypes.SMS);
            return available;
        });
        const isIOS = computed(() => {
            return /iPhone|iPad|iPod/i.test(navigator.userAgent);
        });
        const isAndroid = computed(() => {
            return /Android/i.test(navigator.userAgent);
        });
        const showIconOnly = computed(() => contactTypes.value.length === 1);
        const canContactInstructorEmail = ref(false);
        const contactTypesDetails = {
            Whatsapp: {
                action: async ($e) => {
                    $e.preventDefault();
                    try {
                        const ciResp = await post(props.instructorContactAction, csrfToken());
                        if (ciResp.status !== 200) {
                            throw new Error();
                        }
                        const ciRespJson = await ciResp.json();
                        if (!ciRespJson?.whats_app) {
                            throw new Error();
                        }
                        const whatsAppID = ciRespJson?.whats_app;
                        let url = `https://api.whatsapp.com/send?phone=${whatsAppID}&text=Hi`;
                        emit('option:whatsapp');
                        handleRedirect(url);
                    }
                    catch {
                        console.error('Failed to open whatsapp');
                    }
                    finally {
                        showTooltip.value = false;
                    }
                },
                icon: WhatsappSvg
            },
            SMS: {
                action: async ($e) => {
                    $e.preventDefault();
                    if (!isMobile.value) {
                        return;
                    }
                    try {
                        const ciResp = await post(props.instructorContactAction, csrfToken());
                        if (ciResp.status !== 200) {
                            throw new Error();
                        }
                        const ciRespJson = await ciResp.json();
                        if (!ciRespJson?.phone) {
                            throw new Error();
                        }
                        const phone = ciRespJson?.phone;
                        handleRedirect(`sms:${phone}`);
                    }
                    catch {
                        console.error('Failed to open Messages');
                    }
                    finally {
                        showTooltip.value = false;
                    }
                },
                icon: CommentSvg
            },
            Email: {
                action: ($e) => {
                    $e.preventDefault();
                    if (!props.handleEmailExternally) {
                        canContactInstructorEmail.value = true;
                    }
                    emit('option:email');
                    showTooltip.value = false;
                },
                icon: EnvelopeSvg
            }
        };
        const handleRedirect = (url) => {
            if (isMobile.value && isIOS.value) {
                // iOS does not open Messages or WhatsUp with window.open()
                window.location.href = url;
            }
            else {
                window.open(url);
            }
        };
        const showContactOptions = ($e) => {
            emit('clicked:contact');
            if (contactTypes.value.length === 1) {
                return contactTypesDetails[contactTypes.value[0]].action($e);
            }
            showTooltip.value = !showTooltip.value;
        };
        return {
            isMobile,
            contactTypes,
            showTooltip,
            showContactOptions,
            contactTypesDetails,
            showIconOnly,
            copiedToClipboardTooltip,
            AvailableContactTypes,
            canContactInstructorEmail
        };
    }
});
